
import { computed, defineComponent, onMounted, PropType, Ref, ref, toRefs, watch } from 'vue'

import ApexChartActions, {
  EVENTS as CHART_EVENTS
} from '@/components/analysis/common/chart-actions/ApexChartActions.vue'
import useChartRange from '@/components/analysis/composable/chart-range'
import useMaximizer from '@/components/composables/maximizer-apex-redux'
import { Dict, rounder } from '@/libs/common'

export default defineComponent({
  name: 'HybridApexChart',
  components: {
    ApexChartActions
  },
  props: {
    nodeIds: {
      type: Array,
      default: () => ['Level1A', 'Level1B', 'Root']
    },
    data: {
      type: Array,
      default: () => []
    },
    legends: {
      type: Array,
      default: () => []
    },
    chartId: {
      type: String,
      default: 'radar-chart'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    cssClasses: {
      default: '',
      type: String
    },
    title: {
      default: undefined,
      type: String
    },
    styles: {
      type: Object as PropType<Partial<CSSStyleDeclaration>>,
      default: () => undefined
    },
    plugins: {
      type: Array,
      default: () => []
    },
    colors: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    autoRange: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const chartEl: Ref<HTMLElement | undefined> = ref(undefined)
    const { min, max } = toRefs(props)
    const { maxBound } = useChartRange(min, max)
    const series: Ref<any[]> = ref([])
    const amin: Ref<number> = ref(Number.MAX_VALUE)
    const amax: Ref<number> = ref(Number.MIN_VALUE)

    const updateData = () => {
      const series_: any[] = []
      let amin_ = 0
      let amax_ = 0
      props.data.forEach((r: any, index: number): any => {
        const dataset: Dict = {}
        const data_: any[] = []
        r.forEach((x: any) => {
          const val = parseFloat(x)
          if (amin_ > val) {
            amin_ = val
          }
          if (amax_ < val) {
            amax_ = val
          }
          data_.push(rounder(val, 4))
        })
        dataset.data = data_
        dataset.name = props.legends[index] || 'task ' + index
        series_.push(dataset)
      })
      amin.value = amin_ < 0 ? 0 : amin_
      amax.value = amax_
      series.value = series_
    }

    watch(() => props.data, updateData)
    onMounted(() => {
      updateData()
    })

    const chartOptions = computed(() => ({
      chart: {
        height: 'auto',
        width: props.width || 500,
        type: 'radar',
        zoom: {
          enabled: true
        }
      },
      yaxis: {
        min: props.autoRange ? amin.value : 0,
        max: props.autoRange ? amax.value : maxBound.value,
        decimalsInFloat: 2,
        forceNiceScale: true
      },
      colors: props.colors || [],
      dataLabels: {
        enabled: true
      },
      stroke: {
        width: 2
      },
      fill: {
        opacity: 0.1
      },
      markers: {
        size: 0
      },
      tooltip: {
        y: {
          formatter: (val: number) => val.toFixed(4)
        }
      },
      xaxis: {
        categories: props.nodeIds
      },
      legend: {
        show: true,
        position: 'bottom'
      },
      title: {
        text: props.title
      }
    }))

    const { isMaximize, toggle, chartStyle, maxStyle } = useMaximizer(props.width)

    return {
      chartEl,
      CHART_EVENTS,
      chartStyle,
      maxStyle,
      series,
      isMaximize,
      toggle,
      chartOptions
    }
  }
})
