/* eslint-disable @typescript-eslint/no-unused-vars */
import { keyBy, size } from 'lodash-es'
import { isEmpty, repeat } from 'ramda'
import { Ref, ref } from 'vue'

import { CriteriaRowType } from '@/components/analysis/composable/hybrid-types'
import {
  AnalysisTask,
  ChartData,
  InputNode,
  InputType,
  NodeSelection,
  normalizeOutcomes,
  OutputNode,
  parseNumberArray,
  ROW_TYPE,
  stateToValues
} from '@/components/analysis/libs/common'
import { Network } from '@/libs/bayes'
import { combinationOf, Dict, simpleRow } from '@/libs/common'
import { objectId } from '@/libs/utils'
import {
  createSession,
  getSessionResults,
  performAnalysis,
  performAnalysis2,
  runSession,
  setSessionParams
} from '@/services/api/analysis'
import { JobInputNode, JobOutputNode } from '@/types/database/job'

export const OMITTED = 'OMIT'
export const ANALYSIS_API: 'v1' | 'v2' | 'v3' = 'v2' // v0, v1 and v2

export const inputNodeMapper = ({ networkKey, key, state, values }: InputNode): JobInputNode => {
  return {
    networkKey,
    key,
    state,
    values
  }
}

export const outputNodeMapper = ({ networkKey, key, utilityVector }: OutputNode): JobOutputNode => {
  return {
    networkKey,
    key,
    utilityVector
  }
}

export const multiplexWhatIfRaw = (networkMap: Dict, rawValue: string): string => {
  /* 
  - First row, e.g. Network,Network1,Network2
  - Second row onwards, 
  - Input node key,value1,value2
  - DetNodeKey,state1,state2
  - ChanceNodeKey,[0;1],[0.5;0.5]
  */
  const networkByName = keyBy(networkMap, 'name')
  // const detRows: string[] = []
  // records?.forEach((record: NodeSelection) => {
  //   const { key, utilityVector, isOutput } = record
  //   if (isOutput) {
  //     detRows.push(`${key},output,[${utilityVector.join(';')}]`)
  //   }
  // })
  let networkNames: string[] = []
  const inputNodes = []
  const outputNodes = []
  const inputCandidates = []
  const outputCandidates = []
  const rows = rawValue.split(/\r?\n/)
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i]
    const cols = row.split(/\s*,\s*/)
    let type = cols[0]
    const key = cols[1]
    if (!type?.length) {
      return ''
    }
    type = type.toLowerCase()
    if (type === 'network') {
      networkNames = cols.slice(1)
    } else if (type === 'input') {
      const inputValues = cols.slice(2)
      inputNodes.push({
        key,
        values: inputValues
      })
      inputCandidates.push(inputValues)
    } else if (type === 'output') {
      const outputValues = cols.slice(2)
      outputNodes.push({
        key,
        values: outputValues
      })
      outputCandidates.push(outputValues)
    }
  }
  if (!networkNames.length) {
    networkNames = [''] // use default
  }
  const inputCombination = combinationOf(inputCandidates)
  const outputCombination = combinationOf(outputCandidates)
  let newRows = ''
  let index = 1
  for (let i = 0; i < networkNames.length; i++) {
    const networkName = networkNames[i]
    const network = networkByName[networkName]
    if (!network) {
      continue
    }
    for (let j = 0; j < inputCombination.length; j++) {
      const c = inputCombination[j]
      const inputRows: string[] = []
      for (let k = 0; k < inputNodes.length; k++) {
        const { key, values } = inputNodes[k]
        if (!network.variableMapByKey[key]) {
          continue
        }
        inputRows.push(`${networkName},${key},input,${values[c[k]]}\n`)
      }
      // ${index},
      for (let l = 0; l < outputCombination.length; l++) {
        const oc = outputCombination[l]
        newRows += inputRows.map((r) => `${index},${r}`).join('')
        for (let m = 0; m < outputNodes.length; m++) {
          const { key, values } = outputNodes[m]
          if (!network.variableMapByKey[key]) {
            continue
          }
          newRows += `${index},${networkName},${key},output,${values[oc[m]]}\n`
        }
        index += 1
      }
    }
  }
  return newRows
}

const normKey = (ckey: string): any[] => {
  if (ckey.indexOf(':')) {
    return ckey.split(':')
  }
  return [null, ckey]
}

export default function useWhatIfNext(
  workspaceId: string,
  networkMap: Ref<Record<string, Network>>,
  nodeSelections: Ref<NodeSelection[]>,
  nodeSelectionMap: Ref<Record<string, NodeSelection>>,
  inputNodeKeys: Ref<string[]>,
  outputNodeKeys: Ref<string[]>,
  tasks: Ref<AnalysisTask[]>,
  taskMap: Ref<Record<string, AnalysisTask>>,
  refreshCallback: () => void,
  successCallback: () => void = () => undefined,
  criteriaRows: Ref<CriteriaRowType[]> | undefined = undefined
): any {
  const whatIfRows: Ref<any[]> = ref([])
  const chartNodeIds: Ref<any[]> = ref([])
  const chartData: Ref<ChartData> = ref([])
  const resultMap: Ref<Record<string, any>> = ref({})
  const isExecuting: Ref<boolean> = ref(false)
  const minOutputValue: Ref<number> = ref(0)
  const maxOutputValue: Ref<number> = ref(100)

  const updateTasksFromWhatIfRows = () => {
    tasks.value?.forEach((task) => {
      const { id: taskId, networkId } = task
      const outputNodes: OutputNode[] = []
      const inputNodes: InputNode[] = []

      whatIfRows.value?.forEach((row: any) => {
        const { type, key } = row
        if (!row[taskId]) {
          return
        }
        const variable = networkMap.value[networkId].variableMapByKey[key]
        if (type === ROW_TYPE.OUTPUT) {
          const { value, outcomes, utilityVector, omitted, networkKey } = row[taskId]
          if (!omitted) {
            outputNodes.push({
              networkKey,
              key,
              utilityVector: utilityVector || nodeSelectionMap.value?.[key].utilityVector,
              outcomes,
              value
            })
          }
        } else if (type === ROW_TYPE.INPUT) {
          const { value, state, values, inputType, omitted, networkKey } = row[taskId]
          if (!omitted && state !== OMITTED) {
            inputNodes.push({
              networkKey,
              key,
              state,
              value,
              values: inputType === InputType.PROBABILITY ? values : stateToValues(variable, state),
              type: inputType
            })
          }
        }
      })
      task.inputNodes = inputNodes
      task.outputNodes = outputNodes
    })
  }

  const updateWhatIfRows = () => {
    const _whatIfRows: any[] = []
    const headerValues: Dict = {}
    const inputNodeKeySet: Set<string> = new Set()
    const outputNodeKeySet: Set<string> = new Set()
    const networkByName = keyBy(networkMap.value, 'name')
    tasks.value?.forEach((task: AnalysisTask, index: number) => {
      headerValues[task.id] = {
        taskIndex: index,
        taskId: task.id,
        task
      }
      task.inputNodes?.forEach((inputNode: InputNode) =>
        inputNodeKeySet.add(
          (inputNode.networkKey ? inputNode.networkKey + ':' : '') + inputNode.key
        )
      )
      task.outputNodes?.forEach((outputNode: OutputNode) =>
        outputNodeKeySet.add(
          (outputNode.networkKey ? outputNode.networkKey + ':' : '') + outputNode.key
        )
      )
    })
    inputNodeKeys.value = Array.from(inputNodeKeySet)
    outputNodeKeys.value = Array.from(outputNodeKeySet)
    _whatIfRows.push({
      name: '',
      key: 'subheader',
      type: ROW_TYPE.ACTIONS,
      ...headerValues
    })
    _whatIfRows.push({
      name: 'input',
      key: 'header-input',
      type: ROW_TYPE.LABEL
    })
    inputNodeKeys.value?.forEach((ckey: string) => {
      const [networkKey, key] = normKey(ckey)
      const inputValues = tasks.value?.reduce((acc: any, task: AnalysisTask, index: number) => {
        const { id, networkId, inputNodes } = task
        const network = networkKey ? networkByName[networkKey] : networkMap.value?.[networkId]
        const variable = network.variableMapByKey[key]
        if (!variable || !inputNodes) {
          return acc
        }
        let inputNode
        if (networkKey) {
          inputNode =
            inputNodes.find(({ key: k, networkKey: nk }) => k === key && networkKey === nk) ||
            undefined
        } else {
          inputNode = inputNodes.filter(({ key: k }) => k === key)?.[0] || undefined
        }
        const states =
          variable?.getAllStates().map(({ state: { name } }) => ({
            value: name,
            label: name
          })) || []
        const values = repeat(0, states.length)
        values[states.length - 1] = 1
        const omitted = inputNode === undefined
        acc[task.id] = {
          networkKey: inputNode?.networkKey || network.name,
          networkName: inputNode?.networkKey || network.name,
          taskIndex: index,
          task,
          omitted,
          taskId: id,
          state: inputNode?.state ? inputNode.state : omitted ? OMITTED : states?.[0].value,
          value: inputNode?.state ? inputNode.state : states?.[0].value,
          values: inputNode?.values?.length ? inputNode.values : values,
          variable,
          states,
          options: [
            {
              value: OMITTED,
              label: OMITTED
            },
            ...states
          ],
          inputType: variable.isDeterministic() ? InputType.STATE : InputType.PROBABILITY
        }
        return acc
      }, {})
      _whatIfRows.push({
        name: key,
        key,
        type: ROW_TYPE.INPUT,
        commonNetworkKey: networkKey,
        ...inputValues
      })
    })
    _whatIfRows.push({
      name: 'output',
      key: 'header-output',
      type: ROW_TYPE.LABEL
    })
    outputNodeKeys.value?.forEach((ckey: string) => {
      const [networkKey, key] = normKey(ckey)
      const outputValues = tasks.value?.reduce((acc: any, task: AnalysisTask, index: number) => {
        const { outputNodes, networkId } = task
        const network = networkKey ? networkByName[networkKey] : networkMap.value?.[networkId]
        const variable = network.variableMapByKey[key]
        let outputNode
        if (networkKey) {
          outputNode =
            outputNodes.find(({ key: k, networkKey: nk }) => k === key && networkKey === nk) ||
            undefined
        } else {
          outputNode = outputNodes.filter(({ key: k }) => k === key)?.[0] || undefined
        }

        let wiResult = null
        let hyResult = null
        const omitted = outputNode === undefined
        let value: any = outputNode?.value || 0
        if (task.result?.whatIfResults?.results) {
          wiResult = task.result?.whatIfResults?.results
          if (wiResult[key]) {
            const utilityVector =
              outputNode?.utilityVector || nodeSelectionMap?.value?.[key]?.utilityVector
            value = Number(normalizeOutcomes(wiResult[key], utilityVector)).toFixed(4)
          }
        }
        if (task.result?.hybridResults?.results) {
          hyResult = task.result?.hybridResults?.results?.outputNodes?.find((outputNode: any) => {
            return outputNode.key == key && outputNode.networkId == networkKey
          })
          if (hyResult) {
            value = Number(hyResult.value).toFixed(4)
          }
        }
        acc[task.id] = {
          omitted,
          outcomes: outputNode?.outcomes || [],
          utilityVector: outputNode?.utilityVector || [],
          networkKey: outputNode?.networkKey || network.name,
          networkName: outputNode?.networkKey || network.name,
          taskId: task.id,
          taskIndex: index,
          value,
          variable,
          outputType: variable.isDeterministic() ? InputType.STATE : InputType.PROBABILITY
        }
        return acc
      }, {})
      _whatIfRows.push({
        name: key,
        nodeKey: key,
        key,
        disabled: false,
        commonNetworkKey: networkKey,
        type: ROW_TYPE.OUTPUT,
        ...outputValues
      })
    })
    if (criteriaRows?.value && tasks.value?.length) {
      _whatIfRows.push({
        name: 'criteria',
        key: 'header-criteria',
        type: ROW_TYPE.LABEL
      })
      criteriaRows.value.forEach(({ parentKey = '', childrenKeys = [] }: CriteriaRowType) => {
        if (!childrenKeys.length) {
          return
        }
        const criteriaValues = tasks.value?.reduce(
          (acc: any, task: AnalysisTask, index: number) => {
            const scoreMap = task?.result?.hybridResults?.results?.scoreMap || {}
            acc[task.id] = {
              parentKey,
              taskId: task.id,
              taskIndex: index,
              value: Number(scoreMap[parentKey] || 0).toFixed(4)
            }
            return acc
          },
          {}
        )
        _whatIfRows.push({
          name: parentKey,
          nodeKey: parentKey,
          key: 'c' + parentKey,
          disabled: false,
          type: ROW_TYPE.CRITERIA,
          ...criteriaValues
        })
      })
    }
    whatIfRows.value = _whatIfRows
  }

  const onMoveUpRow = (record: any, rowIndex: number) => {
    const targetRowIndex = rowIndex - 1
    if (targetRowIndex < 0 || whatIfRows.value[targetRowIndex].type !== record.type) {
      return
    }
    let newWhatIfRows: any[] = []
    newWhatIfRows = newWhatIfRows.concat(whatIfRows.value.slice(0, targetRowIndex))
    newWhatIfRows.push(whatIfRows.value[rowIndex])
    newWhatIfRows.push(whatIfRows.value[targetRowIndex])
    if (rowIndex + 1 < whatIfRows.value.length) {
      newWhatIfRows = newWhatIfRows.concat(whatIfRows.value.slice(rowIndex + 1))
    }
    whatIfRows.value = newWhatIfRows
    updateTasksFromWhatIfRows()
  }

  const onMoveDownRow = (record: any, rowIndex: number) => {
    const targetRowIndex = rowIndex + 1
    if (
      targetRowIndex > whatIfRows.value.length - 1 ||
      whatIfRows.value[targetRowIndex].type !== record.type
    ) {
      return
    }
    let newWhatIfRows: any[] = []
    if (rowIndex > 1) {
      newWhatIfRows = newWhatIfRows.concat(whatIfRows.value.slice(0, rowIndex))
    }
    newWhatIfRows.push(whatIfRows.value[targetRowIndex])
    newWhatIfRows.push(whatIfRows.value[rowIndex])
    if (targetRowIndex + 1 < whatIfRows.value.length) {
      newWhatIfRows = newWhatIfRows.concat(whatIfRows.value.slice(targetRowIndex + 1))
    }
    whatIfRows.value = newWhatIfRows
    updateTasksFromWhatIfRows()
  }

  const updateRowsWithResult = (taskId: string, result: any) => {
    whatIfRows.value?.forEach((row: any) => {
      const { key, type } = row
      if (type === ROW_TYPE.OUTPUT) {
        const data = row[taskId]
        if (result[key]) {
          const outcomes = result[key]
          let utilityVector = nodeSelectionMap?.value?.[key]?.utilityVector // default
          if (taskMap.value[taskId]) {
            const cOutputNode = taskMap.value[taskId].outputNodes?.find(
              (outputNode) => outputNode.key == key
            )
            if (cOutputNode) {
              utilityVector = cOutputNode.utilityVector
            }
          }
          data.outcomes = outcomes
          data.value = Number(normalizeOutcomes(outcomes, utilityVector)).toFixed(4)
        }
      }
    })
  }

  const updateRowsDefaultValues = (taskId: string, result: any) => {
    whatIfRows.value?.forEach((row: any) => {
      const { key, type } = row
      if (type === ROW_TYPE.INPUT) {
        const data = row[taskId]
        if (result[key] && data) {
          const outcomes: number[] = result[key]
          if (outcomes) {
            if (data?.inputType === InputType.PROBABILITY) {
              data.values = outcomes.map((outcome) => Number(outcome).toFixed(4))
            } else {
              const max = Math.max(...outcomes)
              const index = outcomes.indexOf(max) || 0
              data.state = data.states?.[index].value
            }
          }
        }
      }
    })
  }

  const updateRowsWithUtilityVector = (updatedNodeKey: string) => {
    tasks.value?.forEach((task) => {
      const { id: taskId } = task
      const result = resultMap.value?.[taskId]
      if (!result) {
        return
      }
      whatIfRows.value?.forEach((row: any) => {
        const { key } = row
        if (key === updatedNodeKey) {
          const data = row[taskId]
          if (result[key]) {
            const outcomes = result[key]
            const utilityVector = nodeSelectionMap?.value?.[key]?.utilityVector
            data.value = Number(normalizeOutcomes(outcomes, utilityVector) * 100).toFixed(4)
          }
        }
      })
    })
    updateChart()
  }

  const updateChart = (selectedTasks: AnalysisTask[] = []) => {
    const _chartData: ChartData = []
    const _chartNodeIds: string[] = []
    const tasksForChart = selectedTasks || tasks.value

    let min = 100
    let max = 0
    tasksForChart.forEach((task, index) => {
      const { id: taskId, status } = task
      const series: number[] = []
      if (status === 'PENDING') {
        return
      }
      whatIfRows.value?.forEach((row: any) => {
        const { key, type } = row
        if (type === ROW_TYPE.OUTPUT && !row.disabled) {
          const data = row[taskId]
          const outcome = data.value
          series.push(outcome)
          if (outcome > max) {
            max = outcome
          }
          if (outcome < min) {
            min = outcome
          }
          if (!index) {
            _chartNodeIds.push(key)
          }
        }
      })
      if (max > 0) {
        maxOutputValue.value = Math.ceil(max)
      }
      if (min < 100) {
        minOutputValue.value = Math.floor(min)
      }
      _chartData.push(series)
    })
    chartData.value = _chartData
    chartNodeIds.value = _chartNodeIds
  }

  const plotCharts = (selectedTasks: Set<AnalysisTask>) => {
    updateChart(Array.from(selectedTasks))
  }

  const clearCharts = () => {
    chartData.value = []
    chartNodeIds.value = []
  }

  /**
   * Update inputNode values for deterministic node, based on selected state
   */
  const updateDeterministicValues = () => {
    tasks.value?.forEach((task) => {
      const { inputNodes, networkId } = task
      inputNodes.forEach((inputNode) => {
        const network = networkMap.value?.[networkId]
        const variable = network.variableMapByKey[inputNode.key]
        if (!variable) {
          return
        }
        const stateLength = variable.getAllStates()?.length
        if (variable.isDeterministic()) {
          if (inputNode.state?.length && inputNode.state !== OMITTED) {
            inputNode.values = stateToValues(variable, inputNode.state)
          } else if (inputNode.state !== OMITTED) {
            const values = repeat(0, stateLength)
            values[stateLength - 1] = 1
            inputNode.values = values
          }
        }
      })
    })
  }

  const executeAnalysis = async () => {
    updateTasksFromWhatIfRows()
    isExecuting.value = true
    // this is temporary
    for (const task of tasks.value) {
      const { inputNodes, outputNodes, networkId, id } = task
      const evidences = inputNodes.reduce((acc: any, inputNode) => {
        const network = networkMap.value?.[networkId]
        const variable = network.variableMapByKey[inputNode.key]
        if (!variable) {
          return acc
        }
        const stateLength = variable.getAllStates()?.length
        if (variable.isDeterministic()) {
          if (inputNode.state?.length && inputNode.state !== OMITTED) {
            acc[inputNode.key] = stateToValues(variable, inputNode.state)
          } else if (inputNode.state !== OMITTED) {
            const values = repeat(0, stateLength)
            values[stateLength - 1] = 1
            acc[inputNode.key] = values
          }
        } else {
          acc[inputNode.key] = inputNode.values
        }
        return acc
      }, {})
      const nodeIds = outputNodes.map((outputNode) => outputNode.key)
      const analysisInput = { networkId, evidences, nodeIds }
      let result
      if (ANALYSIS_API === 'v2') {
        result = await performAnalysis2(workspaceId, analysisInput)
      } else if (ANALYSIS_API === 'v1') {
        result = await performAnalysis(workspaceId, analysisInput)
      } else {
        // @todo: test after session fix on the backend
        const session = await createSession(workspaceId)
        const sessionId = session.id as string
        await setSessionParams(workspaceId, sessionId, analysisInput)
        await runSession(workspaceId, sessionId)
        result = await getSessionResults(workspaceId, sessionId, { nodeIds })
      }
      task.status = 'SUCCESS'
      resultMap.value[id] = result
      updateRowsWithResult(id, result)
      if (refreshCallback) {
        refreshCallback()
        updateChart()
      }
    }
    isExecuting.value = false
  }

  const preExecuteAnalysis = async () => {
    updateTasksFromWhatIfRows()
    isExecuting.value = true
    // this is temporary
    for (const task of tasks.value) {
      const { inputNodes, outputNodes, networkId, id } = task
      const evidences = {}
      const nodeIds: string[] = inputNodes
        .map((inputNode) => inputNode.key)
        .concat(outputNodes.map((outputNode) => outputNode.key))
      const analysisInput = { networkId, evidences, nodeIds }
      let result
      if (ANALYSIS_API === 'v2') {
        result = await performAnalysis2(workspaceId, analysisInput)
      } else if (ANALYSIS_API === 'v1') {
        result = await performAnalysis(workspaceId, analysisInput)
      } else {
        // @todo: test after session fix on the backend
        const session = await createSession(workspaceId)
        const sessionId = session.id as string
        await setSessionParams(workspaceId, sessionId, analysisInput)
        await runSession(workspaceId, sessionId)
        result = await getSessionResults(workspaceId, sessionId, { nodeIds })
      }
      task.status = 'SUCCESS'
      updateRowsDefaultValues(id, result)
      updateRowsWithResult(id, result)
      if (refreshCallback) {
        refreshCallback()
      }
    }
    isExecuting.value = false
  }

  const parseWhatIfTasksRaw = (rawValue: string): any => {
    /* 
    - task no, e.g 1
    - network name, e.g BRVM (name in the XDSL)
    - variable name, e.g. fp1
    - input/output, e.g. 'input' or 'output'
    - state/probabilities/utility vector, e.g. Effective for deterministic State, chance [-0.2;0.2] or UV [0;20;80]
    */
    if (!size(networkMap.value)) {
      return {
        error: 'No network is available'
      }
    }
    const inputNodeKeys = []
    const outputNodeKeys = []
    const inputNodeKeyMap: Dict = {}
    const outputNodeKeyMap: Dict = {}
    const networkByName = keyBy(networkMap.value, 'name')
    const firstNetwork = networkMap.value[Object.keys(networkMap.value)[0]]
    const rows = rawValue.split(/\r?\n/)
    const taskCandidates = []
    let taskCandidate: any = null
    let currentTaskKey = null
    let isNumberTaskKey
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i].trim()
      if (!row.length) {
        continue
      }
      const cols = row.split(/\s*,\s*/)
      const taskKey = cols[0]
      isNumberTaskKey = !isNaN(parseInt(taskKey))
      if (currentTaskKey !== taskKey) {
        if (currentTaskKey !== null) {
          taskCandidates.push(taskCandidate)
        }
        currentTaskKey = taskKey
        taskCandidate = {
          name: isNumberTaskKey ? `Task-${taskKey}` : taskKey,
          network: undefined,
          inputNodes: [],
          outputNodes: []
        }
      }
      const networkName = cols[1] || ''
      let network: Network
      if (isEmpty(networkName)) {
        network = firstNetwork
      } else if (networkName in networkByName) {
        network = networkByName[networkName]
      } else {
        return {
          error: 'Can not find the named network',
          line: i
        }
      }
      const networkKey = network.name
      // need to refactor, a task can have multiple network
      taskCandidate.network = network
      const key = cols[2]
      const type = cols[3]
      if (type !== 'input' && type !== 'output') {
        return {
          error: 'Type should be input or output',
          line: i
        }
      }
      const isInput = type === 'input'
      const value = cols[4] || null
      if (!(key in network.variableMapByKey)) {
        return {
          error: 'The node key can not be found',
          line: i
        }
      }
      const variable = network.variableMapByKey[key]
      const allStates = variable.getAllStates()
      if (!allStates.length) {
        return {
          error: 'No state is available',
          line: i
        }
      }

      if (isInput) {
        const isDeterministic = variable.isDeterministic()
        if (isDeterministic) {
          let stateName: string | null = value
          const allStateNames = allStates.map(({ state }) => state.name)
          if (stateName) {
            if (allStateNames.indexOf(stateName) === -1) {
              return {
                error: 'State can not be found',
                line: i
              }
            }
          } else {
            stateName = allStateNames[allStates.length - 1]
          }
          taskCandidate?.inputNodes.push({
            key,
            networkKey,
            type: isDeterministic ? InputType.STATE : InputType.PROBABILITY,
            state: stateName
          })
        } else {
          let values
          // chance node
          if (!value) {
            values = [0.0, 1.0]
          } else {
            const { numbers } = parseNumberArray(value)
            if (!numbers) {
              return {
                error: 'Incorrect probabilities value',
                line: i
              }
            } else if (numbers.length !== allStates.length) {
              return {
                error: 'Mismatch in probabilities length',
                line: i
              }
            }
            values = numbers.map((number) => number / 100)
          }
          taskCandidate?.inputNodes.push({
            key,
            networkKey,
            type: isDeterministic ? InputType.STATE : InputType.PROBABILITY,
            values
          })
        }
        if (!(key in inputNodeKeyMap)) {
          inputNodeKeys.push(key)
          inputNodeKeyMap[key] = 1
        }
      } else {
        let utilityVector
        if (!value) {
          utilityVector = [0.0, 1.0]
        } else {
          const { numbers } = parseNumberArray(value)
          if (!numbers) {
            return {
              error: 'Incorrect utility vector',
              line: i
            }
          } else if (numbers.length !== allStates.length) {
            return {
              error: 'Incorrect utility vector length',
              line: i
            }
          }
          utilityVector = numbers
        }
        taskCandidate?.outputNodes.push({
          networkKey,
          key,
          utilityVector
        })
        if (!(key in outputNodeKeyMap)) {
          outputNodeKeys.push(key)
          outputNodeKeyMap[key] = 1
        }
      }
    }
    taskCandidates.push(taskCandidate) // push the last one

    const _tasks: AnalysisTask[] = []
    taskCandidates.forEach((taskCandidate: any, index: number) => {
      const { network, inputNodes, outputNodes, name } = taskCandidate
      const _task: AnalysisTask = {
        id: objectId(),
        name: `${name}`,
        networkId: network.id,
        networkKey: network.name,
        isPersisted: false,
        status: 'PENDING',
        inputNodes,
        outputNodes
      }
      _tasks.push(_task)
    })
    return {
      newTasks: _tasks,
      outputNodeKeys,
      inputNodeKeys
    }
  }

  const exportTabular = (): any => {
    const datas: any[] = []
    const names = ['Tasks']
    const data: any[] = []

    const header: any[] = ['Name']
    tasks.value.forEach((task: any) => {
      header.push(task.name)
    })
    data.push(simpleRow(header))
    for (let i = 0; i < whatIfRows.value.length; i++) {
      const row = whatIfRows.value[i]
      if (row.type === ROW_TYPE.ACTIONS) {
        continue
      } else if (row.type === ROW_TYPE.LABEL) {
        data.push(simpleRow([row.name]))
      } else if (row.type === ROW_TYPE.INPUT) {
        const dataRow: string[] = [row.name]
        tasks.value.forEach((task: any) => {
          if (row[task.id].omitted) {
            dataRow.push('')
          } else if (row[task.id].inputType === InputType.STATE) {
            dataRow.push(row[task.id]?.state)
          } else if (row[task.id].inputType === InputType.PROBABILITY) {
            dataRow.push(
              row[task.id]?.values?.map((v: number) => Number(v * 100).toFixed(4)).join(';')
            )
          }
        })
        data.push(simpleRow(dataRow))
      } else if (row.type === ROW_TYPE.OUTPUT) {
        const dataRow: any[] = [row.name]
        tasks.value.forEach((task: any) => {
          if (row[task.id].omitted) {
            dataRow.push('')
          } else {
            dataRow.push(row[task.id]?.value)
          }
        })
        data.push(simpleRow(dataRow))
      } else if (row.type === ROW_TYPE.CRITERIA) {
        const dataRow: any[] = [row.name]
        tasks.value.forEach((task: any) => {
          if (row[task.id].omitted) {
            dataRow.push('')
          } else {
            dataRow.push(row[task.id]?.value)
          }
        })
        data.push(simpleRow(dataRow))
      }
    }
    datas.push(data)
    return {
      names,
      datas
    }
  }

  return {
    plotCharts,
    updateDeterministicValues,
    exportTabular,
    parseWhatIfTasksRaw,
    isExecuting,
    chartData,
    chartNodeIds,
    whatIfRows,
    ROW_TYPE,
    minOutputValue,
    maxOutputValue,
    onMoveDownRow,
    onMoveUpRow,
    updateWhatIfRows,
    executeAnalysis,
    preExecuteAnalysis,
    updateRowsWithUtilityVector,
    updateTasksFromWhatIfRows,
    updateChart,
    clearCharts
  }
}
