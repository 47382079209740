
import 'splitpanes/dist/splitpanes.css'

import { IconCalculator, IconDeviceFloppy, IconFileImport } from '@tabler/icons-vue'
import { Pane, Splitpanes } from 'splitpanes'
import { computed, defineComponent, onMounted, PropType, Ref, ref, toRef, watch } from 'vue'

import { scalingMethodOptions } from '@/components/analysis/composable/hybrid-criteria'
import useHybridResponses from '@/components/analysis/composable/hybrid-responses'
import { MapOfResults } from '@/components/analysis/composable/hybrid-results'
import useHybridSelector from '@/components/analysis/composable/hybrid-selector'
import useHybridSurveyStatus from '@/components/analysis/composable/hybrid-survey-status'
import { CriteriaRowType, ParentRowType } from '@/components/analysis/composable/hybrid-types'
import { percentFormatter, probFormatter, sleep } from '@/libs/common'
import { useStore } from '@/store'
import { UserStateEnum } from '@/store/enums/states'
import { User, Workspace } from '@/types'

import RawImporter, { EVENTS as IMPORT_EVENTS } from '../common/raw-importer/RawImporter.vue'
import HybridCriteriaSetSelector, { EVENTS as SELECT_EVENTS } from './HybridCriteriaSetSelector.vue'
import HybridCriteriaWeights, { EVENTS as WEIGHTS_EVENTS } from './HybridCriteriaWeights.vue'
import HybridPairwiseTable, { EVENTS as PAIRWISE_EVENTS } from './HybridPairwiseTable.vue'
import HybridStats from './HybridStats.vue'

export const EVENTS = {
  LOAD_RESULTS: 'LOAD_RESULTS',
  REFRESH_RESULTS: 'REFRESH_RESULTS',
  OVERWRITE_WEIGHTS: 'OVERWRITE_WEIGHTS'
}

const CONSOLIDATE_USER_ID = '888888888888888888888888'

export default defineComponent({
  components: {
    RawImporter,
    IconDeviceFloppy,
    IconCalculator,
    IconFileImport,
    Pane,
    Splitpanes,
    HybridCriteriaSetSelector,
    HybridPairwiseTable,
    HybridCriteriaWeights,
    HybridStats
  },
  props: {
    workspaceId: { type: String, required: true },
    workspace: { type: Object, required: true },
    // childrenKeys: { type: Array as PropType<string[]>, default: undefined },
    criteriaRows: { type: Array as PropType<CriteriaRowType[]>, default: undefined },
    criteriaMap: { type: Object, default: () => ({}) },
    parentRows: { type: Object as PropType<ParentRowType[]>, default: () => ({}) },
    currentUserId: { type: String, required: true },
    currentUser: { type: Object, required: true },
    networkMap: { type: Object, default: undefined },
    currentResultsMap: { type: Object as PropType<MapOfResults>, required: true },
    isConsolidated: { type: Boolean, default: false }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const isImportVisible: Ref<boolean> = ref(false)
    const tableMatrix: Ref<any> = ref(null)
    const tableMatrixKey: Ref<number> = ref(0)
    const tableRows: Ref<any> = ref(null)
    const tableRowsKey: Ref<number> = ref(0)
    const table: Ref<any> = ref(null)
    const tableKey: Ref<number> = ref(0)
    const completedOnly: Ref<boolean> = ref(false)
    const store = useStore()
    const userList = computed(() => store.state.user[UserStateEnum.USER_LIST]?.content || [])
    const selectedScalingMethod: Ref<string> = ref('LINEAR')
    const workspaceRef = toRef(props, 'workspace') as Ref<Workspace>
    const userMap = computed(() => {
      const result: Record<string, User> = {}
      userList.value?.forEach((each: User) => (result[each.id] = each))
      return result
    })
    // const userMapByName = computed(() => {
    //   const result: Record<string, User> = {}
    //   userList.value?.forEach((each: User) => (result[each.username] = each))
    //   return result
    // })

    const { dataSource, loadSurveyStatus } = useHybridSurveyStatus(
      props.workspaceId,
      workspaceRef,
      userList
    )
    const completedMap = computed(() =>
      dataSource.value.reduce((acc: any, s: any) => {
        if (s.status === 'COMPLETED') {
          acc[s.userId] = 1
        }
        return acc
      }, {})
    )

    const updateTable = () => {
      tableKey.value += 1
      table.value?.$forceUpdate(table.value)
      tableMatrixKey.value += 1
      tableMatrix.value?.$forceUpdate(tableMatrix.value)
      tableRowsKey.value += 1
      tableRows.value?.$forceUpdate(tableRows.value)
    }
    const currentResultsMap = toRef(props, 'currentResultsMap')
    const currentUserId = toRef(props, 'currentUserId')
    const criteriaMap = toRef(props, 'criteriaMap')
    const parentRows = toRef(props, 'parentRows')

    const userOptions = computed(() => {
      let options = userList.value.map(({ id, username }: User) => ({
        label: username,
        value: id
      }))
      options = [
        ...options,
        {
          label: 'consolidate',
          value: CONSOLIDATE_USER_ID
        }
      ]
      return completedOnly.value ? options.filter((o: any) => completedMap.value[o.value]) : options
    })

    const selectedUserName = computed(() => {
      return props.isConsolidated
        ? userMap.value[selectedUserId.value]?.username
        : props.currentUser?.username
    })

    const {
      selectedParentKey,
      selectedUserId,
      pairwiseRows,
      criteriaWeights,
      childrenKeys,
      matrix,
      stats,
      syncResults,
      refreshResults
    } = useHybridSelector(
      props.workspaceId,
      currentUserId,
      currentResultsMap,
      props.isConsolidated,
      selectedScalingMethod,
      () => {}
    )

    const {
      initResponses,
      parseRawResponses,
      currentResponsesMap,
      exportHybrid,
      updatePairwise,
      calculateResponses,
      updatePairwiseResponses,
      isPersistingResponses,
      storeResponses,
      saveResponses,
      loadResponses
    } = useHybridResponses(
      props.workspaceId,
      currentUserId,
      selectedUserId,
      criteriaMap,
      childrenKeys,
      selectedParentKey,
      props.isConsolidated,
      parentRows,
      pairwiseRows,
      updateTable
    )

    const isLoading = computed(() => isPersistingResponses.value)

    const updateData = () => {
      if (childrenKeys.value) {
        criteriaWeights.value = childrenKeys.value?.map((key: string) => ({
          criterionKey: key,
          key,
          elicitedWeight: 0,
          calculatedWeight: 0,
          error: 0
        }))
        updatePairwise()
        updatePairwiseResponses()
        updateTable()
        emit(EVENTS.REFRESH_RESULTS)
      }
    }

    watch(selectedUserId, updateData)
    watch(selectedParentKey, updateData)
    watch(childrenKeys, updateData)
    // onMounted(() => updateData)
    // updateData()

    watch(() => props.workspace, loadSurveyStatus)
    onMounted(() => {
      loadResponses()
      loadSurveyStatus()
      // loadResults()
    })

    watch(criteriaWeights, () => {
      // loadResponses()
      emit(EVENTS.LOAD_RESULTS)
    })

    const onPairwiseUserChange = (parentKey: string) => {
      storeResponses(parentKey)
    }

    const saveAll = () => {
      saveResponses()
    }

    const calculateViaResponse = async () => {
      await saveResponses()
      await sleep(500)
      await calculateResponses()
      setTimeout(() => {
        emit(EVENTS.LOAD_RESULTS)
        updateTable()
      }, 500)
    }

    const overwriteWeights = (criteriaWeights_: any[]) => {
      emit(
        EVENTS.OVERWRITE_WEIGHTS,
        selectedUserId.value,
        selectedParentKey.value,
        criteriaWeights_
      )
    }

    const onSelectParentKey = (parentKey: string, cKeys: string[]) => {
      selectedParentKey.value = parentKey
      childrenKeys.value = cKeys
    }

    const exportHybridHandler = () => {
      exportHybrid(userMap.value[selectedUserId.value])
    }

    const importResponses = () => {
      isImportVisible.value = true
    }

    const importResponsesOk = async (rawText: string) => {
      isImportVisible.value = false
      if (!currentResponsesMap.value?.[selectedUserId.value]) {
        // await saveResponses(true) // saveResponses first to initialize
        initResponses()
      }
      parseRawResponses(rawText)
      updatePairwiseResponses()
      updateTable()
    }

    return {
      selectedUserName,
      importResponsesOk,
      isImportVisible,
      IMPORT_EVENTS,
      importResponses,
      currentResponsesMap,
      exportHybridHandler,
      completedOnly,
      saveAll,
      matrix,
      stats,
      syncResults,
      refreshResults,
      calculateViaResponse,
      onPairwiseUserChange,
      scalingMethodOptions,
      selectedUserId,
      userOptions,
      WEIGHTS_EVENTS,
      SELECT_EVENTS,
      onSelectParentKey,
      pairwiseRows,
      selectedScalingMethod,
      probFormatter,
      selectedParentKey,
      childrenKeys,
      percentFormatter,
      PAIRWISE_EVENTS,
      CONSOLIDATE_USER_ID,
      tableMatrix,
      tableMatrixKey,
      tableRows,
      tableRowsKey,
      table,
      tableKey,
      criteriaWeights,
      overwriteWeights,
      isLoading
    }
  }
})
